import { flow, types } from "@gadgetinc/mobx-quick-tree";
import type { FetchData } from "use-http";
import { identifyUserId } from "../../lib/eventTracker";
import { genericNegativeToaster } from "../../lib/utils";
import type { TurnstileInstance } from "@marsidev/react-turnstile";

/**
 * A note on why we have a maximum limit of 72. Bcrypt supports only 72 bytes, after which everything is truncated.
 * This is not an issue as it is extraordinarily rare that a user will genuinely try to use a password that long,
 * And even if someone does, 72 characters is still insanely strong.
 * We limit it because infinitely large password inputs open up a potential DOS vector and silent truncation is a poor experience.
 *
 * NOTE: Currently, this is duplicated in `packages/api/src/services/auth-utils.ts`
 */
const maximumPasswordLength = 72;
const minimumPasswordLength = 8;

/** Tells us if what the user input is a likely-legal email address; it is not for validation in any way */
export const simpleEmailRegex = /\S+@\S+\.\S/;

type Issue = string | string[];

export const SignUpOrSignInForm = types
  .model({
    email: types.optional(types.string, ""),
    emailDone: types.optional(types.boolean, false),
    emailDirty: types.optional(types.boolean, false),
    password: types.optional(types.string, ""),
    passwordDone: types.optional(types.boolean, false),
    passwordDirty: types.optional(types.boolean, false),
    shouldShowEmailInput: types.optional(types.boolean, true),
    shouldShowRequestPasswordReset: types.optional(types.boolean, false),
    resetRequested: types.optional(types.boolean, false),
    signup: types.optional(types.boolean, false),
    emailErrors: types.array(types.string),
    emailVerified: types.maybe(types.boolean),
    userVerified: types.optional(types.boolean, false),
    error: types.maybe(types.string),
    warning: types.maybe(types.string),
    message: types.maybe(types.string),
    token: types.maybe(types.string),
    emailSent: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false),
    cfTurnstileRequiresInteraction: types.optional(types.boolean, false),
    cfTurnstileToken: types.maybe(types.string),
  })
  .views((self) => ({
    get showTurnstile() {
      if (self.emailDirty) {
        return self.loading && self.passwordDone && self.cfTurnstileRequiresInteraction;
      } else {
        return self.loading && self.cfTurnstileRequiresInteraction;
      }
    },
    get showOAuthLoading() {
      return !self.emailDirty && self.loading;
    },
  }))
  .volatile(() => ({
    cfTurnstile: null as TurnstileInstance | null,
  }))
  .actions((self) => ({
    setCfTurnstile(instance: TurnstileInstance | null) {
      self.cfTurnstile = instance ?? null;
    },
  }))
  .views((self) => ({
    get shouldShowPasswordInput() {
      return !!(!self.emailSent && !self.shouldShowRequestPasswordReset && self.userVerified);
    },
    get passwordHasSufficientLength() {
      return self.password.length >= minimumPasswordLength;
    },
    get passwordHasNumber() {
      return /\d/.test(self.password);
    },
    get passwordExceedsMaxLength() {
      return self.password.length > maximumPasswordLength;
    },
  }))
  .views((self) => ({
    get shouldShowForgotPassword() {
      return self.shouldShowPasswordInput && !self.signup && !self.warning;
    },
    get validEmail(): boolean {
      return !!self.email && simpleEmailRegex.test(self.email);
    },
    get validPassword(): boolean {
      return !!self.password && self.passwordHasSufficientLength && self.passwordHasNumber && !self.passwordExceedsMaxLength;
    },
    get shouldShowContinueEmail() {
      return !self.userVerified && !self.shouldShowRequestPasswordReset && !self.emailSent;
    },
  }))
  .views((self) => ({
    get shouldShowEmailError() {
      return self.shouldShowContinueEmail && self.emailDone;
    },
    get validForm() {
      return self.validEmail;
    },
    get hasEmailError(): boolean {
      const isDirty = !self.shouldShowContinueEmail || self.emailDirty;
      return self.emailDone && isDirty && !self.validEmail;
    },
    get hasLoginPasswordError(): boolean {
      return self.passwordDone && !self.password;
    },
    get hasPasswordError(): boolean {
      return self.passwordDone && !self.validPassword;
    },
    get shouldShowPasswordRequirements() {
      return self.passwordDirty && !self.emailSent && !self.shouldShowRequestPasswordReset && self.signup && self.userVerified;
    },
    get shouldShowResendLink() {
      return self.emailSent && !self.shouldShowForgotPassword;
    },
  }))
  .actions((self) => ({
    clearWarning() {
      self.warning = undefined;
    },
    clearError() {
      self.error = undefined;
    },
    clearMessage() {
      self.message = undefined;
    },
  }))
  .actions((self) => ({
    setEmail(email: string) {
      self.clearWarning();
      self.clearError();
      self.email = email;
      self.emailDone = false;
      self.emailDirty = true;
      self.resetRequested = false;
      self.message = "";
    },
    setEmailDone() {
      self.emailDone = true;
    },
    setPassword(password: string) {
      self.password = password;
      self.passwordDone = false;
      self.passwordDirty = true;
    },
    setPasswordDone() {
      self.passwordDone = true;
    },
    setRequestPassword(showRequest = true) {
      self.shouldShowRequestPasswordReset = showRequest;
    },
    setError(error: Issue) {
      self.clearMessage();
      self.error = maybeJoinStringArray(error);
    },
    setWarning(warning: Issue) {
      self.clearMessage();
      self.warning = maybeJoinStringArray(warning);
    },
    setMessage(message: Issue) {
      self.message = maybeJoinStringArray(message);
    },
    setCfTurnstileToken(token: string) {
      self.cfTurnstileToken = token;
    },
    setCfTurnstileRequiresInteraction(value: boolean) {
      self.cfTurnstileRequiresInteraction = value;
    },
    resetCfTurnstile() {
      self.cfTurnstileToken = undefined;
      self.cfTurnstile?.reset();
    },
  }))
  .actions((self) => ({
    endLoading() {
      self.loading = false;
    },
  }))
  .actions((self) => ({
    completeLoading() {
      setTimeout(self.endLoading, 250);
    },
    showPasswordReset() {
      self.setEmailDone();
      self.setPasswordDone();
      self.setRequestPassword();
      self.clearError();
      self.clearWarning();
    },
    handleIssues(response: { error?: Issue; warning?: Issue; message?: string }) {
      let hasIssues = false;

      if (response.error) {
        hasIssues = true;
        self.setError(response.message ?? response.error);
      } else {
        self.setError("");
      }

      if (response.warning) {
        hasIssues = true;
        self.setWarning(response.warning);
      } else {
        self.setWarning("");
      }

      self.setMessage("");

      return hasIssues;
    },
    ensureCfTurnstileToken: flow(function* () {
      if (self.cfTurnstileToken) return;
      if (!self.cfTurnstile) {
        throw new Error("Unexpected Error: Could not find Turnstile instance.");
      }

      try {
        const token = yield self.cfTurnstile.getResponsePromise(10000);
        self.setCfTurnstileToken(token);
      } catch (err) {
        throw new Error("Something went wrong, please try again.");
      }
    }),
  }))
  .actions((self) => ({
    confirmEmail: flow(function* (confirmEmailAction: FetchData) {
      if (!self.validEmail) return;
      try {
        self.loading = true;
        const response = yield confirmEmailAction({ email: self.email });
        const hasIssues = self.handleIssues(response);
        if (!hasIssues) {
          if (self.error) {
            throw new Error(self.error);
          } else if (response.redirect) {
            window.location.href = "/auth/team";
          } else {
            self.setEmailDone();
            if (response.message) self.setMessage(response.message);
          }
        }
      } catch (err: any) {
        genericNegativeToaster(
          getMessageFromError(err, `Could not confirm email: ${self.email}. Please try again, or contact our support team`)
        );
      }
      self.completeLoading();
    }),
    resetPassword: flow(function* (resetPasswordAction: FetchData) {
      if (!self.token || !self.validPassword) return;
      try {
        self.loading = true;
        const response = yield resetPasswordAction({ token: self.token, password: self.password });
        const hasIssues = self.handleIssues(response);
        if (!hasIssues) {
          if (self.error) {
            throw new Error(self.error);
          } else {
            window.location.href = `/auth/team`;
          }
        } else {
          self.completeLoading();
        }
      } catch (err: any) {
        genericNegativeToaster(getMessageFromError(err, "Could not reset password. Please try again, or contact our support team."));
        self.completeLoading();
      }
    }),
    signIn: flow(function* (signInAction: FetchData, bypassCfTurnstile = false) {
      if (!self.validEmail) return;
      try {
        self.loading = true;

        if (!bypassCfTurnstile) {
          yield self.ensureCfTurnstileToken();
        }

        const response = yield signInAction({ email: self.email, password: self.password, cfTurnstileToken: self.cfTurnstileToken });
        const hasIssues = self.handleIssues(response);
        if (!hasIssues) {
          window.location.href = `/auth/team`;
        } else {
          self.completeLoading();
        }
      } catch (err: any) {
        genericNegativeToaster(getMessageFromError(err, "Incorrect password for this email address."));
        self.resetCfTurnstile();
        self.completeLoading();
      }
    }),
    signInWithOAuth: flow(function* (href: string, bypassCfTurnstile: boolean) {
      try {
        self.loading = true;

        if (!self.cfTurnstileToken && !bypassCfTurnstile) {
          yield self.ensureCfTurnstileToken();
        }

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("cfTurnstileToken", self.cfTurnstileToken ?? "");
        window.location.href = `${href}?${queryParams.toString()}`;
      } catch (err: any) {
        genericNegativeToaster(getMessageFromError(err, "Could not sign in. Please try again, or contact our support team."));
        self.resetCfTurnstile();
        self.completeLoading();
      }
    }),
    createUser: flow(function* (createUserAction: FetchData, bypassCfTurnstile = false) {
      if (!self.validEmail || !self.validPassword) return;
      try {
        self.loading = true;

        if (!self.cfTurnstileToken && !bypassCfTurnstile) {
          yield self.ensureCfTurnstileToken();
        }
        const response = yield createUserAction({ email: self.email, password: self.password, cfTurnstileToken: self.cfTurnstileToken });
        const hasIssues = self.handleIssues(response);
        if (!hasIssues && response.user !== "undefined") {
          if (response.message) self.setMessage(response.message);
          self.token = response.token;
          self.emailSent = true;
          self.emailDirty = false;
          self.setEmailDone();
          self.setPasswordDone();
          self.resetCfTurnstile();
        }
      } catch (err: any) {
        genericNegativeToaster(
          getMessageFromError(err, `Could not create account for email: ${self.email}. Please try again, or contact our support team.`)
        );
        self.resetCfTurnstile();
      }

      self.completeLoading();
    }),
    resendLink: flow(function* (resendLinkAction: FetchData) {
      if (!self.validEmail) return;
      try {
        self.loading = true;
        const response = yield resendLinkAction({ email: self.email });
        const hasIssues = self.handleIssues(response);
        const params = new URLSearchParams();
        params.append("email", self.email);
        if (response.userNotFound) {
          params.append("userNotFound", "1");
          window.location.href = `/auth/signup?${params}`;
        } else if (response.userAlreadyVerified) {
          params.append("emailLogin", "1");
          window.location.href = `/auth/login?${params}`;
        } else if (!hasIssues) {
          if (response.message) self.setMessage(response.message);
          self.token = response.token;
          self.setEmailDone();
        }
      } catch (err: any) {
        genericNegativeToaster(
          getMessageFromError(
            err,
            `Could not send verification link to email: ${self.email}. Please try again, or contact our support team.`
          )
        );
      }
      self.completeLoading();
    }),
    requestReset: flow(function* (requestResetAction: FetchData) {
      if (!self.validEmail) return;
      try {
        self.loading = true;
        const response = yield requestResetAction({ email: self.email });
        const hasIssues = self.handleIssues(response);
        if (!hasIssues && response.message) {
          self.setMessage(response.message);
          self.emailSent = true;
          self.setRequestPassword(false);
          self.shouldShowEmailInput = false;
          self.token = response.token;
          self.resetRequested = true;
          self.setEmailDone();
        }
      } catch (err: any) {
        genericNegativeToaster(
          getMessageFromError(
            err,
            `Could not send password reset link to email: ${self.email}. Please try again, or contact our support team.`
          )
        );
      }
      self.completeLoading();
    }),
  }))
  .actions((self) => ({
    checkEmail: flow(function* (checkEmailAction: FetchData) {
      self.emailDirty = true;
      self.setEmailDone();
      if (!self.validEmail) return;

      try {
        self.loading = true;
        const response = yield checkEmailAction({ email: self.email });
        const hasIssues = self.handleIssues(response);

        // Check userId
        if (response.userId) {
          try {
            identifyUserId(response.userId);
          } catch (err: any) {
            // Fail silently
          }
        }

        if (!hasIssues) {
          self.emailVerified = response.verified;
          self.signup = response.newUser;
          self.clearError();
          self.clearWarning();
          if (response.message) self.setMessage(response.message);
          self.userVerified = true;
        }
      } catch (err: any) {
        genericNegativeToaster(getMessageFromError(err, `Could not verify email: ${self.email}`));
      }

      self.completeLoading();
    }),
  }));

type Error = Issue | { warning?: string; message?: string } | undefined;

export const getMessageFromError = (error: Error, fallback?: string) => {
  if (typeof error === "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join(". ");
  } else if (typeof error === "object" && error) {
    return error.warning ?? error.message ?? fallback;
  }
  return fallback;
};

export const maybeJoinStringArray = (message?: string | string[]) => {
  if (Array.isArray(message)) message = message.join(". ");
  return message;
};
